main#content {
  min-height: 300px;
}

.loadingLogo {
  width: 200px;
  animation-name: pulse;
  animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    opacity: 0.3;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.95);
    opacity: 0.3;
  }
}
