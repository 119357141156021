.fc-event-title {
  line-height: 1.1rem;
  font-size: 0.8rem;
  text-overflow: ellipsis;

  /* -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden; */
  /* text-overflow: ellipsis; */
  /* display: -webkit-box; */
}

.fc-event-time {
  font-size: 0.8rem !important;
}

.timetable-special-start {
  background: #7accaa !important;
  color: white !important;
}

.timetable-special-break {
  background: #e6e8eb !important;
  color: #96a2af !important;
}

.timetable-special-finish {
  background: #f0da17 !important;
  color: #8e8213 !important;
}

.fc-event-main-frame {
  color: #1e547f;
  padding: 4px;
  padding-right: 8px;
  padding-bottom: 0;
  background: #c5d5e3;
  border-radius: 4px;
  overflow: hidden;
  /* border: 1px solid #9fa9b2; */
}

:root {
  --fc-event-border-color: transparent;
  --fc-event-bg-color: transparent;
}
