.Home-booking-component-tutor {
  max-height: 8rem;
  top: -2em;
  display: inline-block;
}

.Home-booking-component-description {
  font-family: 'Fabrikat-Medium';
  color: #1f2243;
}

.Home-booking-component-status-button {
  text-align: center;
  min-width: 156px;
}

.Home-booking-component-status-button img {
  height: 80px !important;
  margin-bottom: 0.4rem;
}

.Home-booking-component-text {
  display: inline-block;
}
