.kt-select .form-control {
  border: 2px solid rgba(31, 34, 68, 0.2);
  border-radius: 4px;
  height: 200px !important;
  overflow: 'show';
}

/* .kt-select .form-control::-webkit-scrollbar {
  width: 0.6em;
}

.kt-select .form-control::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px rgba(3, 1, 1, 0.3);
  -webkit-box-shadow: inset 0 0 4px rgba(3, 1, 1, 0.3);
  border-radius: 16px;
}
.kt-select .form-control::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 8px;
} */
